import { createSelector } from "@reduxjs/toolkit";
import {
  assetByPageNumber,
  createRailInstance,
  getAssetInfo,
  getRailInstanceById,
  searchAssets,
  updateRailInstance,
} from "helpers/mb-api_helper";
import isoLangs from "lib/languages";
// import { Image, X } from "lucide-react";
import { Image, X } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Popup from "reactjs-popup";
import Select from "react-select";
import { DatePicker, SelectPicker, TagInput, Toggle } from "rsuite";
import { ReactSortable } from "react-sortablejs";
import FilterEditorialSection from "components/railsComponent/FilterEditorialSection";

interface SortOption {
  field: {
    key: string;
    value: string;
  };
  order: string[];
}

interface CommonProps {
  handleRailInfoChange: (i: number, field: string, value: any) => void; // Updated signature to accept multiple arguments
  rail: any; // Individual rail object
  i: number;
  disabled?: boolean;
  railType: string;
}
const EditorialEditRailsSection: React.FC<CommonProps> = ({
  rail,
  handleRailInfoChange,
  i,
  disabled = false,
  railType,
}) => {
  const selectProperties = createSelector(
    (state: any) => state.User,
    (user) => ({
      meta: user.meta,
    })
  );
  const { meta } = useSelector(selectProperties);
  const assetType = meta.assetTypes;
  const assetTypeOption = assetType
    ? assetType.map((v: any) => {
        return { label: v, value: v };
      })
    : [];

  const [showPlaceholderPopup, setShowPlaceholderPopup] = useState(false);
  const [selectedAssetType, setSelectedAssetType] = useState<any>(null);
  const [assetTypeData, setAssetTypeData] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [placeholderData, setPlaceholderData] = useState<any>([]);
  const [filterOption, setFilterOption] = useState([]);
  const [filters, setFilters] = useState(rail.filterData || [{}]);
  const [sortOptions, setSortOptions] = useState<SortOption[]>([]);

  const handleAssetChange = async (asset: any) => {
    try {
      // Await the API call to fetch assets of type
      // const assetsRes: any = await getAssetInfo(asset.value);
      const type = asset.value;
      const currentPage = 1;
      const searchTerm = "";
      const sort = "v_tt:desc";
      // use different api for get updated 1st asset
      const assetsRes: any = await assetByPageNumber(
        type,
        currentPage,
        searchTerm,
        sort
      );
      setAssetTypeData(assetsRes.list);
      setSelectedAssetType(asset);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = async (searchQuery: any) => {
    try {
      // Await the API call to fetch assets
      let currTypeFilters: any = {
        v_type: selectedAssetType.value,
      };
      let assetsRes: any = await searchAssets(currTypeFilters, searchQuery);
      setAssetTypeData(assetsRes.list);
    } catch (error) {
      console.error(error);
    }
  };

  const addFilter = () => {
    setFilters([...filters, {}]);
  };
  const removeFilter = (index: number) => {
    setFilters((prevFilters: any) =>
      prevFilters.filter((data: any, i: number) => i !== index)
    );
  };

  const handleChangeFilterDataValue = (
    index: number,
    key: string,
    value: any
  ) => {
    const newFilter: any = filters;
    newFilter[index][key] = value;
    setFilters(() => [...newFilter]);
  };

  const getPlaceholderData = async (id: string) => {
    try {
      const data: any = await getRailInstanceById(id);
      setPlaceholderData(data?.assets);
    } catch (e) {}
  };

  const updatePlaceholderRailInstance = async (id: string, data: any) => {
    try {
      const instance = await updateRailInstance(id, { assets: data });
    } catch (e) {
      console.error("this is updateRailInstance", e);
    }
  };

  const createPlaceholderRailInstance = async (data: any) => {
    try {
      const instance: any = await createRailInstance({ assets: data });
      handleRailInfoChange(i, "railInstanceId", instance?.id);
    } catch (e) {}
  };

  useEffect(() => {
    if (!disabled) handleRailInfoChange(i, "filterData", filters);
  }, [filters]);

  useEffect(() => {
    if (railType !== "filter")
      if (placeholderData.length > 0)
        if (rail.railInstanceId !== "" && rail.railInstanceId) {
          updatePlaceholderRailInstance(rail?.railInstanceId, placeholderData);
        } else {
          createPlaceholderRailInstance(placeholderData);
        }
  }, [placeholderData]);

  useEffect(() => {
    const options = meta?.filterOptions.map((option: any) => ({
      label: option.label,
      value: option.name,
    }));

    setFilterOption(options);
    setSortOptions(meta?.sortOptions);
    setFilters(rail.filterData || []);
    if (
      rail.railInstanceId !== "" &&
      rail.railInstanceId &&
      railType !== "filter"
    ) {
      getPlaceholderData(rail.railInstanceId);
    }
  }, []);

  return (
    <>
      <div className="col-span-full flex flex-col gap-3">
        <p className="font-semibold text-lg">Sort Option</p>
        <div className="grid gap-6 lg:grid-cols-2">
          <div className="flex flex-col gap-px">
            <label className="text-xs">Sort By</label>
            <SelectPicker
              disabled={disabled}
              data={sortOptions?.map((option: any) => ({
                label: option?.field?.value,
                value: option?.field?.key,
              }))}
              className="border !border-mb-blue rounded-md w-full"
              onChange={(v, e) => handleRailInfoChange(i, "sortData", v)}
              value={rail.sortData || []}
            ></SelectPicker>
          </div>

          <div className="flex flex-col gap-px">
            <label className="text-xs">Value</label>
            <SelectPicker
              disabled={disabled}
              data={
                rail.sortData !== ""
                  ? sortOptions
                      ?.find(
                        (obj: SortOption) => obj?.field?.key === rail.sortData
                      )
                      ?.order?.map((val) => ({ label: val, value: val })) || []
                  : []
              }
              className="border !border-mb-blue rounded-md w-full"
              onChange={(v, e) => handleRailInfoChange(i, "sortValues", v)}
              value={rail.sortValues || []}
            ></SelectPicker>
          </div>
        </div>
      </div>

      <div className="col-span-full flex flex-col gap-3">
        <p className="font-semibold text-lg">Filter Option</p>
        {filters.map((data: any, index: number) => (
          <div
            className="card p-4 grid gap-x-6 lg:grid-cols-3"
            key={index + data?.filterData}
          >
            <div className="col-span-full flex justify-end">
              {!disabled && (
                <button
                  onClick={() => removeFilter(index)}
                  className="bg-mb-red  disabled:bg-gray-100 rounded-full right-1 top-1 text-white p-px"
                >
                  <X size={15} />
                </button>
              )}
            </div>
            <FilterEditorialSection
              disabled={disabled}
              // handleSelectFilterData={handleSelectFilterData}
              index={index}
              data={data}
              handleChangeFilterDataValue={handleChangeFilterDataValue}
              filterOption={filterOption}
            />
          </div>
        ))}

        {/* Button to add a new filter */}
        <div>
          {!disabled && (
            <button
              onClick={addFilter}
              className="bg-mb-blue rounded-md text-white hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1"
            >
              Add Filter
            </button>
          )}
        </div>
      </div>

      {railType !== "filter" && (
        <div className="col-span-full flex flex-col gap-4">
          <label className="text-xs">Placeholder</label>
          <div>
            {!disabled && (
              <button
                onClick={() => setShowPlaceholderPopup(true)}
                className="bg-mb-blue rounded-md text-white hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1"
              >
                + Add Assets
              </button>
            )}
          </div>
          <div className="flex gap-4">
            {placeholderData.length > 0 && (
              <ReactSortable
                list={placeholderData}
                setList={setPlaceholderData}
                className="flex overflow-x-auto scrollbar-hide"
              >
                {placeholderData?.map((data: any, index: number) => {
                  return (
                    <button
                      key={index + data?.title}
                      // disabled={true}
                      className="text-gray-800 relative m-2 pb-2 bg-gray-100 w-[210px] rounded-lg text-center shadow min-w-[200px]"
                    >
                      {!disabled && (
                        <button
                          onClick={() => {
                            setPlaceholderData(
                              placeholderData.filter(
                                (placeholder: any) => placeholder.id !== data.id
                              )
                            );
                          }}
                          className="bg-mb-red absolute disabled:bg-gray-100 rounded-full right-1 top-1 text-white p-px"
                        >
                          <X size={15} />
                        </button>
                      )}
                      <Toggle
                        disabled={disabled}
                        className="my-2"
                        checked={data?.state === "active"}
                        value={"Active"}
                        checkedChildren={"Active"}
                        unCheckedChildren={"Inactive"}
                        onChange={(v) => {
                          setPlaceholderData((preVal: any) =>
                            preVal.map((val: any, i: number) =>
                              i === index
                                ? { ...val, state: v ? "active" : "inactive" }
                                : val
                            )
                          );
                        }}
                      />

                      {data.img !== "" && data.img ? (
                        <div className="relative w-52 h-32">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_CDN}${data.img}`}
                            alt={data.title}
                            className="mx-auto mb-2 object-cover h-full w-full"
                          />
                        </div>
                      ) : (
                        <span className="p-1 rounded-full border border-slate-300">
                          <Image size={90} className="text-slate-300 mx-auto" />{" "}
                        </span>
                      )}
                      <p>{data.title}</p>
                      <div className="mt-2 text-left px-4">
                        <label>From</label>
                        <DatePicker
                          disabled={disabled}
                          oneTap
                          format="MM/dd/yyyy HH:mm"
                          className="border !border-mb-blue rounded-md w-full"
                          onChange={(v, e) =>
                            setPlaceholderData((preVal: any) =>
                              preVal.map((val: any, i: number) =>
                                i === index
                                  ? {
                                      ...val,
                                      window: {
                                        ...val?.window,
                                        from: v?.getTime(),
                                      },
                                    }
                                  : val
                              )
                            )
                          }
                          value={
                            data && data.window && data.window.from
                              ? new Date(data.window.from)
                              : null
                          }
                        />
                      </div>
                      <div className="mt-2 text-left px-4">
                        <label>To</label>
                        <DatePicker
                          disabled={disabled}
                          oneTap
                          format="MM/dd/yyyy HH:mm"
                          className="border !border-mb-blue rounded-md w-full"
                          onChange={(v, e) =>
                            setPlaceholderData((preVal: any) =>
                              preVal.map((val: any, i: number) =>
                                i === index
                                  ? {
                                      ...val,
                                      window: {
                                        ...val?.window,
                                        to: v?.getTime(),
                                      },
                                    }
                                  : val
                              )
                            )
                          }
                          value={
                            data && data.window && data.window.to
                              ? new Date(data.window.to)
                              : null
                          }
                        />
                      </div>
                    </button>
                  );
                })}
              </ReactSortable>
            )}
          </div>
        </div>
      )}

      <Popup
        modal
        open={showPlaceholderPopup}
        onClose={() => {
          setShowPlaceholderPopup(false);
        }}
        overlayStyle={{
          background: "rgba(0,0,0,0.8)",
          zIndex: 999999999,
        }}
        lockScroll
        closeOnDocumentClick
      >
        <div className="bg-white flex flex-col rounded-md md:w-[30rem] p-4 overflow-y-auto mb-5">
          <div className="flex py-2 px-4 border-b border-b-mb-blue/50 justify-between items-center">
            <h6>Add New</h6>
            <button onClick={() => setShowPlaceholderPopup(false)}>
              <X size={15} />
            </button>
          </div>
          <div className="grid lg:grid-cols-2 gap-10 justify-center mb-5">
            {assetType && (
              <>
                <div className="flex flex-col">
                  <Select
                    // isMulti
                    value={selectedAssetType}
                    onChange={(asset: any) => handleAssetChange(asset)}
                    options={assetTypeOption}
                    placeholder="Select Type"
                    className="selectize capitalize"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    className="form-input placeholder:capitalize"
                    placeholder="Search assets..."
                    value={searchTerm}
                    onChange={(e: any) => {
                      setSearchTerm(e.target.value);
                      handleSearch(e.target.value);
                    }}
                  />
                </div>
              </>
            )}
          </div>
          <div className="flex flex-col rounded-md border border-white-light dark:border-[#1b2e4b] h-[70vh] ">
            {assetTypeData &&
              assetTypeData.map((item: any, idx: any) => (
                <div className="grid">
                  <div
                    key={idx}
                    className="gap-10 border-b flex border-white-light dark:border-[#1b2e4b] px-4 py-2.5 bg-white text-black hover:bg-primary hover:text-mb-blue shadow-[0_1px_15px_1px_rgba(67,97,238,0.15)]"
                  >
                    <input
                      id="assetSelectIds"
                      className="form-checkbox"
                      type="checkbox"
                      checked={placeholderData
                        .map((placeholder: any) => placeholder.id)
                        .includes(item.id)}
                      onChange={(e: any) => {
                        if (e.target.checked) {
                          let newPlaceholderData = placeholderData;
                          let newAssetItem = {
                            id: item.id,
                            title: item.title,
                            img: item?.images
                              ? item?.images[0]?.path || ""
                              : "",
                            state: "active",
                          };
                          newPlaceholderData.push(newAssetItem);
                          setPlaceholderData([...newPlaceholderData]);
                        } else {
                          let newPlaceholderData = placeholderData.filter(
                            (placeholder: any) => placeholder.id !== item.id
                          );
                          setPlaceholderData(newPlaceholderData);
                        }
                      }}
                      // disabled={
                      //   placeholderData.length >= formData &&
                      //   formData[id] &&
                      //   formData[id].placeholders &&
                      //   !placeholderData
                      //     .map((placeholder: any) => placeholder.id)
                      //     .includes(item.id)
                      // }
                    />
                    <label>{item.title} </label>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Popup>
    </>
  );
};

export default EditorialEditRailsSection;
