import BreadCrumb from "Common/BreadCrumb";
import Alert from "Common/Components/Alert";
import MbLoader from "Common/Components/Icons/MbLoader";
import {
  getRoles,
  getUsers,
  createUser,
  updateUsers,
} from "helpers/mb-api_helper";
import { Copy, Edit3Icon, Plus, X } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import { SelectPicker, Toggle } from "rsuite";

const UserList = () => {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formData, setFormData] = useState<any>({});
  const [createError, setCreateError] = useState("");
  const [loading, setLoading] = useState(true);
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const [usersData, setUsersData] = useState<any>([]);
  const [rolesData, setRolesData] = useState<
    { value: string; label: string }[]
  >([]);

  const updateUserDetail = async (userData: any, id: string) => {
    try {
      const userDetails: any = await updateUsers(userData, id);
      setUsersData(
        usersData?.map((data: any) => {
          if (data.id === id) {
            return userDetails;
          }
          return data;
        })
      );
      setSuccess("update a user");
      setTimeout(() => {
        setSuccess("");
      }, 5000);
    } catch (error) {
      setError("An error occurred while creating the user");
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  };

  const handleCloseAddPopup = () => {
    setOpenAddPopup(false);
    setFormData({});
    setConfirmPassword("");
    setCreateError("");
  };
  const createNewUser = async () => {
    if (
      !formData?.name ||
      !formData?.email ||
      !formData?.password ||
      !formData?.role
    ) {
      return setCreateError("Please fill all fields values");
    }
    if (formData?.password !== confirmPassword) {
      return setCreateError("Confirm password is different from password");
    }

    // Email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData?.email && !emailPattern.test(formData.email)) {
      return setCreateError("Please enter a valid email address");
    }

    try {
      const data = await createUser({ ...formData, active: 1 });
      setUsersData((preVal: any) => [data, ...preVal]);
      setSuccess("Create a new user");
      handleCloseAddPopup();
    } catch (error) {
      setCreateError("An error occurred while creating the user");
    }
  };

  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        const users: any = await getUsers();
        setUsersData(users?.list);
        setLoading(false);
        setSuccess("");
      } catch (error) {
        setError("Unable to get Users list");
        setLoading(false);
      }
    };
    const fetchRolesData = async () => {
      try {
        const roles: any = await getRoles();
        setRolesData(
          roles?.list.map((v: any) => ({ value: v.name, label: v?.name }))
        );
        setLoading(false);
        setSuccess("");
      } catch (error) {
        setError("Unable to get users list");
        setLoading(false);
      }
    };

    fetchRolesData();
    fetchUsersData();
  }, []);

  return (
    <React.Fragment>
      <div className="w-full min-h-screen flex flex-col text-xs">
        <BreadCrumb title={`Manage Users`} pageTitle="Users" />

        <div className="card">
          <div className="card-body">
            <div className="errors success">
              {success && (
                <Alert className="relative px-4 py-3 text-sm text-green-500 border border-transparent rounded-md bg-green-50 dark:bg-green-400/20">
                  <Alert.Close className="absolute top-0 bottom-0 right-0 p-3 transition text-custom-200 hover:text-green-500 dark:text-custom-400/50 dark:hover:text-custom-500" />
                  <Alert.Bold>Success! </Alert.Bold>
                  {success}
                </Alert>
              )}
              {error && (
                <Alert className="relative px-4 py-3 text-sm text-orange-500 border border-transparent rounded-md bg-orange-50 dark:bg-orange-400/20">
                  <Alert.Close className="absolute top-0 bottom-0 right-0 p-3 transition text-custom-200 hover:text-orange-500 dark:text-custom-400/50 dark:hover:text-custom-500" />
                  <Alert.Bold>Error! </Alert.Bold>
                  {error}
                </Alert>
              )}
            </div>
            <div className="flex justify-end my-2">
              <button
                onClick={() => setOpenAddPopup(true)}
                className="bg-mb-blue rounded-md text-white hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1"
              >
                Add New <Plus size={10} />{" "}
              </button>
            </div>
            {!loading && (
              <div className="overflow-x-auto scrollbar-hide border border-slate-200 rounded-md">
                <table className="w-full">
                  <thead className="ltr:text-left rtl:text-right ">
                    <tr>
                      <th className="px-3.5 min-w-[80px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">
                        ID
                      </th>
                      <th className="px-3.5 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">
                        Name
                      </th>
                      <th className="px-3.5 min-w-[80px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">
                        role
                      </th>

                      <th className="px-3.5 sticky right-0 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500 text-right bg-white">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersData.map((data: any, i: number) => {
                      return (
                        <tr
                          key={data.id}
                          className="even:bg-slate-50 hover:bg-slate-50 even:hover:bg-slate-100 dark:even:bg-zink-600/50 dark:hover:bg-zink-600 dark:even:hover:bg-zink-600"
                        >
                          <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">
                            {data?.id}
                          </td>
                          <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">
                            {data?.name}
                          </td>
                          <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">
                            {data?.role}
                          </td>
                          <td className="px-3.5 sticky right-0 py-2.5 border-y border-slate-200 dark:border-zink-500 flex justify-end gap-4">
                            <Toggle
                              checked={data.active}
                              value={"true"}
                              checkedChildren={"Active"}
                              unCheckedChildren={"Inactive"}
                              onChange={(v: any) => {
                                updateUserDetail(
                                  { ...data, active: v ? 1 : 0 },
                                  data?.id
                                );
                                // setUserData((preVal: any) => ({
                                //   ...preVal,
                                //   active: v ? 1 : 0,
                                // }))
                              }}
                            />
                            <Link
                              to={`${data?.id}`}
                              className="w-auto py-0.5 font-bold text-mb-blue"
                            >
                              <Edit3Icon
                                className="inline-block mr-2"
                                size={15}
                              />
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>

        {loading && <MbLoader />}

        <Popup
          modal
          overlayStyle={{ background: "rgba(0,0,0,0.7)", zIndex: 9999999999 }}
          lockScroll
          open={openAddPopup}
          onClose={handleCloseAddPopup}
          closeOnDocumentClick={false}
        >
          <div className="bg-white flex flex-col rounded-md md:w-[30rem]">
            <div className="flex py-2 px-4 border-b border-b-mb-blue/50 justify-between items-center">
              <h6>Add New</h6>
              <button onClick={handleCloseAddPopup}>
                <X size={15} />
              </button>
            </div>
            <div className="flex flex-col gap-2 px-4 py-8">
              <div className="flex flex-col gap-2">
                <label htmlFor="addTitle">Name</label>
                <input
                  value={formData?.name}
                  onChange={(e) => {
                    setFormData({ ...formData, name: e.target.value });
                  }}
                  type="text"
                  className="px-1 py-2 h-10 border border-mb-blue rounded-md"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label htmlFor="addTitle">Role</label>
                <SelectPicker
                  data={rolesData}
                  menuStyle={{ zIndex: 9999999999999999 }}
                  onChange={(v, e) => setFormData({ ...formData, role: v })}
                  //   value={formData?.role ? formData?.role : []}
                  placeholder="Select options"
                  className="border !border-mb-blue !text-black rounded-md w-full"
                ></SelectPicker>
              </div>
              <div className="flex flex-col gap-2">
                <label htmlFor="addTitle">Email</label>
                <input
                  value={formData?.email}
                  onChange={(e) => {
                    setFormData({ ...formData, email: e.target.value });
                  }}
                  type="text"
                  className="px-1 py-2 h-10 border border-mb-blue rounded-md"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label htmlFor="addTitle">Password</label>
                <input
                  value={formData?.password}
                  onChange={(e) => {
                    setFormData({ ...formData, password: e.target.value });
                  }}
                  type="text"
                  className="px-1 py-2 h-10 border border-mb-blue rounded-md"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label htmlFor="addTitle">Confirm Password</label>
                <input
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                  type="text"
                  className="px-1 py-2 h-10 border border-mb-blue rounded-md"
                />
              </div>
            </div>
            <div className="flex py-2 px-4 border-t border-t-mb-blue/50 justify-end gap-4 items-center">
              <button
                className="bg-mb-red text-white rounded-md hover:bg-mb-red/30 hover:text-mb-red px-4 py-2 flex items-center gap-1"
                onClick={() => {
                  handleCloseAddPopup();
                }}
              >
                Cancel
              </button>
              <button
                className="bg-mb-blue text-white rounded-md hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1"
                onClick={createNewUser}
              >
                Submit
              </button>
            </div>
            <div className="flex justify-end m-2">
              {createError && <p className=" text-mb-red">{createError}</p>}
            </div>
          </div>
        </Popup>
      </div>
    </React.Fragment>
  );
};

export default UserList;
