import BreadCrumb from "Common/BreadCrumb";
import Alert from "Common/Components/Alert";
import MbLoader from "Common/Components/Icons/MbLoader";
import { getRoles, updateRoles } from "helpers/mb-api_helper";
import { ShieldCheck } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const RoleEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const selectUserMeta = (state: any) => state.User.meta;
  const meta = useSelector(selectUserMeta);

  const [rowNames, setRowNames] = useState<string[]>([]);
  const [columnName, setColumnName] = useState<string[]>([]);
  const [roleData, setRoleData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const capitalFirstLetter = (input: string) => {
    return input
      .toLowerCase() // Convert the entire string to lowercase first
      .replace(/_/g, " ") // Replace all underscores with spaces
      .replace(/\b\w/g, (char: any) => char.toUpperCase()); // Capitalize the first letter of each word
  };

  const toggleRolePermission = (e: any, action: string) => {
    const isChecked = e.target.checked;

    setRoleData((prevRoleData: any) => {
      const updatedActions = prevRoleData?.acl?.actions
        ? prevRoleData.acl.actions.filter((data: string) => data !== action)
        : []; // Initialize as an empty array if actions is undefined

      return {
        ...prevRoleData,
        acl: {
          ...prevRoleData.acl,
          actions: isChecked ? [...updatedActions, action] : updatedActions,
        },
      };
    });
  };

  const toggleAllRolePermission = (isChecked: boolean, value: string) => {
    const updatedActions = isChecked
      ? addPermissions(value)
      : removePermissions(value);

    setRoleData((prevRoleData: any) => ({
      ...prevRoleData,
      acl: { actions: updatedActions },
    }));
  };

  const addPermissions = (value: string) => {
    const allReadArray = rowNames.map((name) => `${name}.${value}`);
    return [...new Set([...(roleData?.acl?.actions || []), ...allReadArray])];
  };

  const removePermissions = (value: string) => {
    return (
      roleData?.acl?.actions.filter(
        (item: string) => !item.endsWith(`.${value}`)
      ) || []
    );
  };

  const handleSeparatedRowAndColumn = () => {
    const beforeDot: string[] = [];
    const afterDot: string[] = [];
    const actions = meta?.acl.actions;

    actions.forEach((action: any) => {
      const lastDotIndex = action.lastIndexOf("."); // Find the last occurrence of a dot
      if (lastDotIndex !== -1) {
        beforeDot.push(action.slice(0, lastDotIndex)); // Add part before the last dot
        afterDot.push(action.slice(lastDotIndex + 1)); // Add part after the last dot
      }
    });
    setRowNames(() => [...new Set(beforeDot)]);
    setColumnName(() => [...new Set(afterDot)]);
  };

  const handleSubmit = async () => {
    try {
      await updateRoles({ name: roleData?.name, acl: roleData?.acl }, id);
      setSuccess("Update data successfully ");
      setTimeout(() => {
        setSuccess("");
      }, 5000);
      navigate("/user/roles");
    } catch (error) {
      setError("Unable to save role data");
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchRoleData = async () => {
      try {
        const roles: any = await getRoles(id);
        setRoleData(roles);
        setLoading(false);
      } catch (error) {
        setError("Unable to get roles list");
        setTimeout(() => {
          setError("");
        }, 5000);
        setLoading(false);
      }
    };
    handleSeparatedRowAndColumn();
    fetchRoleData();
  }, [id]);
  return (
    <React.Fragment>
      <BreadCrumb
        backRoute={`/user/roles`}
        title="Edit Role"
        pageTitle="Role"
      />

      {loading ? (
        <MbLoader />
      ) : (
        <div className=" p-4 border rounded-lg shadow-lg">
          <div className=" p-4 border border-slate-300">
            <label className="inline-block mb-2 text-base font-medium">
              Name
            </label>
            <input
              type="text"
              className="px-1 py-2 h-9 border !border-mb-blue rounded-md w-full disabled:bg-gray-100 disabled:cursor-not-allowed"
              value={roleData?.name || ""}
              onChange={(e) => {
                setRoleData((prevRoleData: any) => ({
                  ...prevRoleData,
                  name: e.target.value,
                }));
              }}
            />
            <div className="overflow-x-auto scrollbar-hide border border-slate-200 rounded-md mt-6 p-4 shadow-lg bg-white">
              <table className="w-full">
                <thead className="ltr:text-left rtl:text-right ">
                  <tr>
                    <th className="px-3.5 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">
                      Name
                    </th>
                    {/* <th className="px-3.5 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">
                      <div className="flex item-center gap-2 ">
                        <input
                          className="peer h-5 w-5 cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md border border-slate-300 checked:bg-blue-600 checked:border-blue-600"
                          type="checkbox"
                          value="true"
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            toggleAllRolePermission(isChecked, "READ");
                          }}
                        />
                        <p>Read</p>
                      </div>
                    </th> */}
                    {columnName?.map((name) => (
                      <th className="px-3.5 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">
                        <div className="flex item-center gap-2 ">
                          <input
                            className="peer h-5 w-5 cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md border border-slate-300 checked:bg-blue-600 checked:border-blue-600"
                            type="checkbox"
                            value="true"
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              toggleAllRolePermission(isChecked, name);
                            }}
                          />
                          <p className="capitalize">
                            {capitalFirstLetter(name)}
                          </p>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {rowNames?.map((row) => (
                    <tr className="even:bg-slate-50 hover:bg-slate-50 even:hover:bg-slate-100 dark:even:bg-zink-600/50 dark:hover:bg-zink-600 dark:even:hover:bg-zink-600">
                      <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">
                        {row}
                      </td>
                      {/* <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">
                        <input
                          className="peer h-5 w-5 cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md border border-slate-300 checked:bg-blue-600 checked:border-blue-600"
                          type="checkbox"
                          checked={roleData?.acl?.actions?.includes(
                            `${row}.READ`
                          )}
                          value="true"
                          onChange={(e) =>
                            toggleRolePermission(e, `${row}.READ`)
                          }
                        />
                      </td> */}
                      {columnName?.map((name) => (
                        <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">
                          {meta?.acl.actions.includes(`${row}.${name}`) ? (
                            <input
                              className="peer h-5 w-5 cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md border border-slate-300 checked:bg-blue-600 checked:border-blue-600"
                              type="checkbox"
                              disabled={meta?.acl.actions.includes(
                                `${row}.${name}`
                              )}
                              checked={roleData?.acl?.actions?.includes(
                                `${row}.${name}`
                              )}
                              value="true"
                              onChange={(e) =>
                                toggleRolePermission(e, `${row}.${name}`)
                              }
                            />
                          ) : (
                            <div className="px-2" >-</div>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-full flex justify-end p-5">
            <button
              type="button"
              className="bg-mb-green rounded-md text-white hover:bg-mb-green/30 hover:text-mb-green px-4 py-2 flex items-center gap-1 text-nowrap disabled:bg-gray-200 disabled:hover:bg-gray-200 disabled:hover:text-white"
              onClick={handleSubmit}
            >
              <ShieldCheck size={15} /> Save
            </button>
          </div>
        </div>
      )}

      <div>
        {success && (
          <Alert className="relative px-4 py-3 text-sm text-green-500 border border-transparent rounded-md bg-green-50 dark:bg-green-400/20">
            <Alert.Close className="absolute top-0 bottom-0 right-0 p-3 transition text-custom-200 hover:text-green-500 dark:text-custom-400/50 dark:hover:text-custom-500" />
            <Alert.Bold>Success! </Alert.Bold>
            {success}
          </Alert>
        )}
        {error && (
          <Alert className="relative px-4 py-3 text-sm text-orange-500 border border-transparent rounded-md bg-orange-50 dark:bg-orange-400/20">
            <Alert.Close className="absolute top-0 bottom-0 right-0 p-3 transition text-custom-200 hover:text-orange-500 dark:text-custom-400/50 dark:hover:text-custom-500" />
            <Alert.Bold>Error! </Alert.Bold>
            {error}
          </Alert>
        )}
      </div>
    </React.Fragment>
  );
};

export default RoleEdit;
