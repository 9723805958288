
import { createSelector } from '@reduxjs/toolkit';
import { slugify } from 'helpers/utility';
import { Info, X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import { DatePicker, TagInput } from 'rsuite';

interface InputProps {
  name:string  
  props:any
  formData?: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>,
  i18n:string
  required?:string[]
}

const DatePickerBox: React.FC<InputProps> = ({name, props, formData={}, setFormData,i18n,required }) =>{
const [showHelper,setShowHelper] = useState(false)
let selectProperties = createSelector(
  (state: any) => state.User,
  (user) => ({
    meta: user.meta,
  })
);
let { meta } = useSelector(selectProperties);
const isRequired = required ?required?.length >1 ? required.includes(name) : false:false

return (
  <div className={`flex flex-col gap-1 ${props.cols?`lg:col-span-${props.cols}`:'lg:col-span-6'}`}>
    <div className='flex gap-1 items-center'>
      <label className='text-sm'>{props.name} {isRequired && <span className='text-red-600 text-base' >*</span>}</label>
      {props.longDesc && 
      <>
        <button className='text-mb-blue' onClick={()=>setShowHelper(true)}><Info size={15} /></button>
        <Popup
          open={showHelper}
          onClose={()=>setShowHelper(false)} 
          modal
          lockScroll
          closeOnDocumentClick
          contentStyle={{top:0,right:0,height:'100vh',position:'fixed'}}
          overlayStyle={{background:'rgba(0,0,0,0.6)',zIndex:999999}}
        >
        <div className='h-screen bg-white w-[20rem] relative'>
          <button onClick={()=>setShowHelper(false)} className='absolute right-4 top-4'><X size={30} /></button>
          <div className='text-xl text-mb-blue border-b border-b-mb-blue font-bold p-4'>{props.longDesc.title}</div>
          <div className='text-sm text-gray-900 p-4 flex flex-col gap-4' dangerouslySetInnerHTML={{__html:props.longDesc.body}}></div>
        </div>  
        </Popup>
      </>
    } 
    </div>
    <span className='text-xs'>{props.shortDesc}</span>
    <DatePicker oneTap disabled={i18n !== meta.lang} format="MM/dd/yyyy HH:mm" readOnly={props.editable?!props.editable:false} className='border !border-mb-blue rounded-md w-full' onChange={(v,e)=>setFormData({...formData,[name]:v})} value={formData[name]?new Date(formData[name]):null} />
    {formData[name] && <span className='text-xs'>{new Date(formData[name]).toUTCString()}</span>}
  </div>
)};

export default DatePickerBox;