import BreadCrumb from "Common/BreadCrumb";
import Alert from "Common/Components/Alert";
import MbLoader from "Common/Components/Icons/MbLoader";
import { getRoles, createRole } from "helpers/mb-api_helper";
import { Copy, Edit3Icon, Plus, X } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";

const ListRoles = () => {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [title, setTitle] = useState("");
  const [createError, setCreateError] = useState("");
  const [copyRoleData, setCopyRoleData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const [rolesData, setRolesData] = useState<any>([]);

  const handleCloseAddPopup = () => {
    setOpenAddPopup(false);
    setTitle("");
    setCopyRoleData({});
    setCreateError("");
  };

  const createRoles = async () => {
    if (title === "") {
      setCreateError("Please enter a title");
      return;
    }

    try {
      if (copyRoleData?.id) {
        const data = await createRole({ acl: copyRoleData?.acl, name: title });
        setRolesData((preVal: any) => [data, ...preVal]);
      } else {
        const data: any = await createRole({ name: title });
        setRolesData((preVal: any) => [data, ...preVal]);
      }
      setSuccess("Create role successfully ");
      setTimeout(() => {
        setSuccess("");
      }, 5000);
      handleCloseAddPopup();
    } catch (error) {
      setCreateError("Unable to create role");
      setTimeout(() => {
        setCreateError("");
      }, 5000);
      setLoading(false);
    }
  };

  const handleCopyRoleData = async (id: string) => {
    try {
      setOpenAddPopup(true);
      const roles: any = await getRoles(id);
      setCopyRoleData(roles);
    } catch (error) {
      setError("Unable to get role data");
      handleCloseAddPopup();
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  };

  useEffect(() => {
    const fetchRolesData = async () => {
      try {
        const roles: any = await getRoles();
        setRolesData(roles?.list);
        setLoading(false);
        setSuccess("");
      } catch (error) {
        setError("Unable to get roles list");
        setLoading(false);
      }
    };
    fetchRolesData();
  }, []);

  return (
    <React.Fragment>
      <div className="w-full min-h-screen flex flex-col text-xs">
        <BreadCrumb title={`Manage Roles`} pageTitle="Roles" />

        <div className="card">
          <div className="card-body">
            <div className="errors success">
              {success && (
                <Alert className="relative px-4 py-3 text-sm text-green-500 border border-transparent rounded-md bg-green-50 dark:bg-green-400/20">
                  <Alert.Close className="absolute top-0 bottom-0 right-0 p-3 transition text-custom-200 hover:text-green-500 dark:text-custom-400/50 dark:hover:text-custom-500" />
                  <Alert.Bold>Success! </Alert.Bold>
                  {success}
                </Alert>
              )}
              {error && (
                <Alert className="relative px-4 py-3 text-sm text-orange-500 border border-transparent rounded-md bg-orange-50 dark:bg-orange-400/20">
                  <Alert.Close className="absolute top-0 bottom-0 right-0 p-3 transition text-custom-200 hover:text-orange-500 dark:text-custom-400/50 dark:hover:text-custom-500" />
                  <Alert.Bold>Error! </Alert.Bold>
                  {error}
                </Alert>
              )}
            </div>
            <div className="flex justify-end my-2">
              <button
                onClick={() => setOpenAddPopup(true)}
                className="bg-mb-blue rounded-md text-white hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1"
              >
                Add New <Plus size={10} />{" "}
              </button>
            </div>
            {!loading && (
              <div className="overflow-x-auto scrollbar-hide border border-slate-200 rounded-md">
                <table className="w-full">
                  <thead className="ltr:text-left rtl:text-right ">
                    <tr>
                      <th className="px-3.5 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">
                        ID
                      </th>
                      <th className="px-3.5 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">
                        Name
                      </th>

                      <th className="px-3.5 sticky right-0 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500 text-right bg-white">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {rolesData.map((asset: any, i: number) => {
                      return (
                        <tr
                          key={asset.id}
                          className="even:bg-slate-50 hover:bg-slate-50 even:hover:bg-slate-100 dark:even:bg-zink-600/50 dark:hover:bg-zink-600 dark:even:hover:bg-zink-600"
                        >
                          <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">
                            {asset?.id}
                          </td>
                          <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">
                            {asset?.name}
                          </td>
                          <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500 flex justify-end gap-4">
                            <button
                              onClick={() => handleCopyRoleData(asset?.id)}
                              className="w-auto py-0.5 font-bold text-mb-blue"
                            >
                              <Copy className="inline-block mr-2" size={15} />
                            </button>
                            <Link
                              to={`${asset?.id}`}
                              className="w-auto py-0.5 font-bold text-mb-blue"
                            >
                              <Edit3Icon
                                className="inline-block mr-2"
                                size={15}
                              />
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>

        {loading && <MbLoader />}

        <Popup
          modal
          overlayStyle={{ background: "rgba(0,0,0,0.7)", zIndex: 9999999999 }}
          lockScroll
          open={openAddPopup}
          onClose={handleCloseAddPopup}
          closeOnDocumentClick
        >
          <div className="bg-white flex flex-col rounded-md md:w-[30rem]">
            <div className="flex py-2 px-4 border-b border-b-mb-blue/50 justify-between items-center">
              <h6>Add New</h6>
              <button onClick={handleCloseAddPopup}>
                <X size={15} />
              </button>
            </div>
            <div className="flex flex-col gap-2 px-4 py-8">
              {createError && (
                <p className="text-xs text-mb-red">{createError}</p>
              )}
              <label htmlFor="addTitle">Title</label>
              <input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                type="text"
                className="px-1 py-2 h-10 border border-mb-blue rounded-md"
              />
            </div>
            <div className="flex py-2 px-4 border-t border-t-mb-blue/50 justify-end gap-4 items-center">
              <button
                className="bg-mb-red text-white rounded-md hover:bg-mb-red/30 hover:text-mb-red px-4 py-2 flex items-center gap-1"
                onClick={() => {
                  handleCloseAddPopup();
                }}
              >
                Cancel
              </button>
              <button
                className="bg-mb-blue text-white rounded-md hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1"
                onClick={createRoles}
              >
                Submit
              </button>
            </div>
          </div>
        </Popup>
      </div>
    </React.Fragment>
  );
};

export default ListRoles;
